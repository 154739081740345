import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
Vue.config.productionTip = false
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/index.scss' // global css

import * as Checker from '@/utils/checker'
import * as Filter from '@/utils/filter'
import * as Base64 from '@/utils/base64'
Vue.use(ElementUI)

// import api from './api/request'
// Vue.prototype.$api = api
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);


new Vue({
  router,
  store,
  Checker,
  Filter,
  Base64,
  render: h => h(App)
}).$mount("#app");
