var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.screen
        ? _c(
            "div",
            {
              staticClass: "box-contain",
              staticStyle: { "text-align": "center", height: "100%" },
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: require("@/assets/img/live_weixin.png") },
              }),
            ]
          )
        : _c("RouterView"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }