import Vue from 'vue'
// 文件已经挂载全局 如有增添写好注释和示例
// 区分币种
/*
 * 整数的格式化
 * 100 -> 100
 * 1000 -> 1,000
 * 1000.11 -> 1,000
 * 1000000 -> 1,000,000
 * */
Vue.prototype.format = function(number) {
  if (number === null || number === undefined || number === '') return ''
  if (number === '-') return number
  return parseInt(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
//        title: 数值运算型方法
//  正数与负数都可以
Vue.prototype.verification = function(con) {
  for (let i = 1; i < String(con).length; i += 1) {
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', '-'].indexOf(String(con).slice(i - 1, i)) === -1 || isNaN(parseFloat(con))) {
      return false
    }
  }
  return true
}

//  计息日期
Vue.prototype.interestDay = function () {
  let dataTime = new Date();
  let www = 0;
  switch (dataTime.getDay()) {
    case 0:
      www = 4; break;
    case 1:
      www = 3; break;
    case 2:
      www = 2; break;
    case 3:
      www = 1; break;
    case 4:
      www = 7; break;
    case 5:
      www = 6; break;
    case 6:
      www = 5; break;
    default:
      break;
  }
  dataTime.setDate(dataTime.getDate() + Number(www))
  let year = dataTime.getFullYear()
  let month = (dataTime.getMonth() + 1) < 10 ? '0' + (dataTime.getMonth() + 1) : (dataTime.getMonth() + 1);
  let day = (dataTime.getDate() ) < 10 ? '0' + (dataTime.getDate()) : (dataTime.getDate());
  let resuleDays = year+'.'+month+'.'+day
  return resuleDays
}
// 到期日期
Vue.prototype.addDays = function(num) {
  let dataTime = new Date();
  dataTime.setDate(dataTime.getDate() + Number(num))
  let year = dataTime.getFullYear()
  let month = (dataTime.getMonth() + 1) < 10 ? '0' + (dataTime.getMonth() + 1) : (dataTime.getMonth() + 1);
  let day = (dataTime.getDate() ) < 10 ? '0' + (dataTime.getDate()) : (dataTime.getDate());
  let resuleDays = year+'.'+month+'.'+day
  return resuleDays
}
// 标准时间转换成年月日时分秒（补0）
Vue.prototype.formatDate =function(timestamp) {
  var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear(),
      M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
    D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()),
    H = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()),
    S = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()),
    m = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
  let result =''
  if (H == "00") {
    result =Y + '-' + M + '-' + D
  } else {
    result =Y + '-' + M + '-' + D +" "+ H +":"+S+":"+m
  }
  
  return result
}
// 隐藏隐私信息*
Vue.prototype.formatHide = function (value) {
  let obtain = ""
  if (value.indexOf('@')>0) {
    obtain= value.substring(0,3)+'***'+value.substring(value.indexOf('@'))
  } else {
    if (value.length > 0 && value.length < 2){
      obtain= "*"
    } else if (value.length > 0 &&value.length < 3) {
      obtain= value.substring(0,1)+'*'
    } else if (value.length > 2 &&value.length < 5) {
      obtain= value.substring(0,1)+'*'+ value.substring(2)// 名称
    } else if (value.length > 4 &&value.length < 12) {
      obtain= value.substring(0,3) +'***'+ value.substring(7)//手机号
    } else {
      obtain= value.substring(0,3) +'*****'+ value.substring(15)//证件
    }
  }
  return obtain
}
// 密码校验
// 判断密码是否是6-16位数字字母
Vue.prototype.checkPwd = function(pwd) {
  var reg = /^(?![0-9]$)(?![a-zA-Z]$)[0-9A-Za-z]{6,16}$/
  var re = new RegExp(reg)
  if (re.test(pwd)) {
    return true
  } else {
    return false
  }
}
// 邮箱校验
Vue.prototype.checkEmail = function(email) {
  var reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,}$/
  var re = new RegExp(reg)
  if (re.test(email)) {
    return true
  } else {
    return false
  }
}
// 邮箱校验
Vue.prototype.checkPhone = function(num) {
  var reg = /^(1\d{10})$/
  var re = new RegExp(reg)
  if (re.test(num)) {
    return true
  } else {
    return false
  }
}
  // 身份证格式校验
Vue.prototype.checkCard = function(num) {
  var reg = /^(\d{18}|\d{15}|\d{17}x)$/
  var re = new RegExp(reg)
  if (re.test(num)) {
    return true
  } else {
    return false
  }
}
  // 仅仅支持两位小数
  Vue.prototype.data2float = function(num) {
    var reg = /^[0-9]+(\.[0-9]{1,2})?$/
    var re = new RegExp(reg)
    if (re.test(num)) {
      return true
    } else {
      return false
    }
  }
   // 正整数
   Vue.prototype.dataNumber = function(num) {
    var reg = /^\+?[1-9][0-9]*$/
    var re = new RegExp(reg)
    if (re.test(num)) {
      return true
    } else {
      return false
    }
  }