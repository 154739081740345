import api from '../../api/request'

const state = {
    token: '',
}

const getters = {
    token: state => {
        return state.token
    }
}

const actions = {
     // 获取验证码
     getcodes({ commit }, params) {
        // console.log(params)
        return new Promise((resolve, reject) => {
            // params
            api.post('/codes',params).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 校验验证码和交易密码
    checkscodes({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.post('/checks', data).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 创建用户
    createUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.post('/users', data).then(res => {
                commit('login', res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 忘记密码，重新设置登录密码
    updateUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.put('/users/'+data.account, data).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 登录
    login({ commit }, params) {
        return new Promise((resolve, reject) => {
            api.post('/tokens', params).then(res => {
                commit('login', res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
   
    // 退出
    logout({ commit }, params) {
        commit('logout',params)
    },
    // 获取系统通知消息
    getMessage({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.get('/message', data).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    validationsAuth({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.post('/validations', data).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
  
}

const mutations = {
    login(state, data) {
        state.token = data.token
        localStorage.setItem('mu_token', data.token)
    },
    
    logout(state) {
        state.token =''
        localStorage.removeItem('mu_token')
        localStorage.removeItem('info')
        localStorage.removeItem('plan_data')
    },
    nothing() {
        
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
