// 交易
import api from '../../api/request'

const state = {}
const getters = {}

const actions = {
    // 获取总资产
    getUserAmount({ commit },params) {
        return new Promise((resolve, reject) => {
            api.get('/users/' + params.params.code, params).then(res => {
                if (params.params.type == "info") {
                    commit('setInfo',res)
                }
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 获取银行卡
    getBanks({ commit },params) {
        return new Promise((resolve, reject) => {
            api.get('/bankAccounts', params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //提现
    withdraws({ commit },params) {
        return new Promise((resolve, reject) => {
            api.post('/withdraws', params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //转账
    transfer({ commit },params) {
        return new Promise((resolve, reject) => {
            api.post('/transfer', params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 获取所有的香港银行
    HKBanks({ commit },params) {
        return new Promise((resolve, reject) => {
            api.get('/banks', params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 添加银行
    addBank({ commit },params) {
        return new Promise((resolve, reject) => {
            api.post('/bankAccounts', params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
     // 删除银行卡
     removeBank({ commit },params) {
        return new Promise((resolve, reject) => {
            api.delete('/bankAccounts', params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
     // 编辑银行卡信息
    getDetaiEdit({ commit },params) {
        return new Promise((resolve, reject) => {
            api.get('/bankAccounts/'+params.params.id, params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 创建充值订单
    recharges({ commit },params) {
        return new Promise((resolve, reject) => {
            api.post('/recharges', params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //预约开户
    reaccount({ commit },params) {
        return new Promise((resolve, reject) => {
            api.post('https://activity.fundusd.com/appoint', params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    orders({ commit },params) {
        return new Promise((resolve, reject) => {
            api.get('/orders/'+params.params.id, params).then(res => {
                commit('nothing')
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    
    
    
    
}

const mutations = {
    nothing(){},
    setInfo(state, data) {
        localStorage.setItem("info",JSON.stringify(data.info))
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
