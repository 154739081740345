import axios from 'axios'
// import { Notification } from 'element-ui';
import { Message } from 'element-ui';
import store from '@/store/index'
import router from '@/router/index'
// import base64 from '../utils/base64'
const req = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  // baseURL: '/v2',
  timeout: 10000,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    
  },
})
req.interceptors.request.use(
  config => {
    const token = store.getters['auth/token'] || localStorage.getItem('mu_token')
    token && (config.headers.Authorization = token)
    return config
  }
)
req.interceptors.response.use(
  response => {
    // if (response.status == 200) {
    //   let data = base64.decode(response.data.substring(2))
    //   data = JSON.parse(data)
    //   console.log(data)
    //   return Promise.resolve(data)
    // } else if (response.status == 201) {
    //   return Promise.resolve(response.data)
    // }
    if (response.status == 200) {
      // let data = base64.decode(response.data.substring(2))
      // data = JSON.parse(data)
      return Promise.resolve(response.data)
    } else if (response.status == 201) {
      return Promise.resolve(response.data)
    }
    
  },
  error => {
    if (error.response.status == 400) {
      Message({
        offset: 100,
        type: 'error',
        message: error.response.data.msg,
        customClass:'message-tips'
      });
      return Promise.reject(error.response.data)
    }
    if (error.response.status == 401) {
      router.replace('/login')
    }
  }
)

export default req