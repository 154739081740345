<template>
  <div id="app">
    <div v-if="screen" class="box-contain" style="text-align: center; height: 100%;">
        <img src="@/assets/img/live_weixin.png" style="width: 100%;">
    </div>
    <RouterView v-else/>
  </div>
</template>
<script>
export default {
  name: 'App',
  data(){
    return{
      screen:false
    }
  },
  created(){
    if (/(micromessenger)/i.test(navigator.userAgent)) {
    //是否电脑微信或者微信开发者工具
      if(/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)){
        this.screen=true
      }else{
        //手机微信打开的浏览器
        this.screen=true
      }
    }
  }
}
</script>
<style scoped>
.box-contain {
  background-color: rgba(0, 0, 0, 0.8);
  filter: alpha(opacity=80);
}
</style>
