import api from '../../api/request'

const state = {}
// 分期
const getters = {}

const actions = {
    // 亚洲分期3种方式
    getIncome({ commit }, params) {
        return new Promise((resolve, reject) => {
            api.get(params.params.term?'/terms/'+params.params.term:'/terms',params).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 购买亚洲分期
    buyStages({ commit },data) {
        return new Promise((resolve, reject) => {
            api.post('/investments',data).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 赎回
    callback({ commit },params) {
        return new Promise((resolve, reject) => {
            api.put('/investments/term',params).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 购买
    buyDetail({ commit },params) {
        return new Promise((resolve, reject) => {
            api.get('/investments/'+params.params.id,params).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 购买
    histories({ commit },params) {
        return new Promise((resolve, reject) => {
            api.get('/insurance/plans/cim?period=5&age=21&type=MN&coverage=',params).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    
    myStagesList({ commit },data) {
        return new Promise((resolve, reject) => {
            api.get('/fixedincomes',data).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    changestatus({ commit },data) {
        return new Promise((resolve, reject) => {
            api.put('/terms/'+data.id,data).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

const mutations = {
    
    nothing() {
        
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
