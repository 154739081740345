import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);


const constantRoutes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home'),
        meta: {
            title: '木星国际'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login'),
        meta: {
            title: '木星国际'
        }
    },{
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register'),
        meta: {
            title: '注册'
        }
    },
    {
        path: '/pwd',
        name: 'pwd',
        component: () => import('@/views/Pwd'),
        meta: {
            title: '忘记密码'
        }
    },
    {
        path: '/forgetpwd',
        name: 'forgetpwd',
        component: () => import('@/views/authentication/forgetTPwd'),
        meta: {
            title: '重置交易密码'
        }
    },
    {
        path: '/stages',
        name: 'stages',
        component: () => import('@/views/Stages'),
        meta: {
            title: '亚洲分期'
        }
    },
    {
        path: '/stagesDetail',
        name: 'stagesDetail',
        component: () => import('@/views/StagesDetail'),
        meta: {
            title: '亚洲分期产品详情'
        }
    },
    {
        path: '/rules',
        name: 'rules',
        component: () => import('@/views/Rules'),
        meta: {
            title: '交易规则'
        }
    },
    {
        path: '/buy',
        name: 'buy',
        component: () => import('@/views/Buy'),
        meta: {
            title: '立即买入'
        }
    },
    {
        path: '/pay',
        name: 'pay',
        component: () => import('@/views/Pay'),
        meta: {
            title: '支付'
        }
    },
    {
        path: '/paysuccess',
        name: 'paysuccess',
        component: () => import('@/views/PaySuccess'),
        meta: {
            title: '预约成功'
        }
    },
    {
        path: '/my',
        name: 'my',
        component: () => import('@/views/My'),
        meta: {
            title: '我的'
        }
    },
    {
        path: '/authentication',
        name: 'authentication',
        component: () => import('@/views/authentication/Index'),
        meta: {
            title: '实名认证'
        }
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/views/authentication/Auth'),
        meta: {
            title: '认证'
        }
    },
    {
        path: '/checks',
        name: 'checks',
        component: () => import('@/views/authentication/binding'),
        meta: {
            title: '绑定手机/邮箱'
        }
    },{
        path: '/changepwd',
        name: 'changepwd',
        component: () => import('@/views/authentication/changePwd'),
        meta: {
            title: '重置密码'
        }
    },
    {
        path: '/notice',
        name: 'notice',
        component: () => import('@/views/Notice'),
        meta: {
            title: '系统通知'
        }
    },
    {
        path: '/noticedetail',
        name: 'noticedetail',
        component: () => import('@/views/NoticDetail'),
        meta: {
            title: '系统通知详情'
        }
    }, {
        path: '/idcard',
        name: 'idcard',
        component: () => import('@/views/authentication/idcard'),
        meta: {
            title: '上传证件'
        }
    },
    {
        path: '/secure',
        name: 'secure',
        component: () => import('@/views/Secure'),
        meta: {
            title: '账户安全'
        }
    },
    {
        path: '/financing',
        name: 'financing',
        component: () => import('@/views/FinancingDetail'),
        meta: {
            title: '我的-定期理财详情'
        }
    },
    {
        path: '/guarantee',
        name: 'guarantee',
        component: () => import('@/views/GuaranteeDetail'),
        meta: {
            title: '我的-保单详情'
        }
    },
    {
        path: '/guaranteedetail',
        name: 'guaranteedetail',
        component: () => import('@/views/insurance/guaranteeDetail'),
        meta: {
            title: '保单详情'
        }
    },
    {
        path: '/insuranceDetail',
        name: 'insuranceDetail',
        component: () => import('@/views/insurance/insuranceDetail'),
        meta: {
            title: '保障范围'
        }
    },
    {
        path: '/financingtpes',
        name: 'financingtpes',
        component: () => import('@/views/FinancingTypesDetail'),
        meta: {
            title: '香港保险'
        }
    },
    {
        path: '/plan',
        name: 'plan',
        component: () => import('@/views/PlanBook'),
        meta: {
            title: '香港保险'
        }
    },
    {
        path: '/insureDetail',
        name: 'insureDetail',
        component: () => import('@/views/InsureDetail'),
        meta: {
            title: 'EAM服务介绍'
        }
    },
    {
        path: '/insuranceComparison',
        name: 'insuranceComparison',
        component: () => import('@/views/InsuranceComparison'),
        meta: {
            title: '险种对比'
        }
    },
    {
        path: '/transferout',
        name: 'transferout',
        component: () => import('@/views/transfer/TransferOut'),
        meta: {
            title: '转出'
        }
    },
    {
        path: '/transferin',
        name: 'transferin',
        component: () => import('@/views/transfer/TransferIn'),
        meta: {
            title: '充值'
        }
    },
    {
        path: '/transferhk',
        name: 'transferhk',
        component: () => import('@/views/transfer/TransferInHK'),
        meta: {
            title: '充值-香港账户汇款'
        }
    },
    {
        path: '/transfero',
        name: 'transfero',
        component: () => import('@/views/transfer/TransferInO'),
        meta: {
            title: '内地/其他账户汇款'
        }
    },
    {
        path: '/reservation',
        name: 'reservation',
        component: () => import('@/views/transfer/reservationAccount'),
        meta: {
            title: '预约开户'
        }
    },
    {
        path: '/banktransfer',
        name: 'banktransfer',
        component: () => import('@/views/transfer/banktransfer'),
        meta: {
            title: '通知客服已汇款'
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/transfer/account'),
        meta: {
            title: '香港开户特邀通道'
        }
    },
    {
        path: '/bank',
        name: 'bank',
        component: () => import('@/views/transfer/bank'),
        meta: {
            title: '选择收款账户'
        }
    },
    {
        path: '/addbank',
        name: 'addbank',
        component: () => import('@/views/transfer/addbank'),
        meta: {
            title: '添加收款账户'
        }
    },
    {
        path: '/transfersuccess',
        name: 'transfersuccess',
        component: () => import('@/views/transfer/transferSuccess'),
        meta: {
            title: '成功'
        }
    },
    // 投资详情
    {
        path: '/investdetail',
        name: 'investdetail',
        component: () => import('@/views/transfer/investdetail'),
        meta: {
            title: '投资详情'
        }
    },
    // 提前转出
    {
        path: '/before',
        name: 'before',
        component: () => import('@/views/transfer/beforeOut'),
        meta: {
            title: '提前转出'
        }
    },
     // 申购协议
     {
        path: '/subscription',
        name: 'subscription',
        component: () => import('@/views/SubscriptionAgreement'),
        meta: {
            title: '申购协议'
        }
    },
     // 保险保护项目
     {
        path: '/insureitems',
        name: 'insureitems',
        component: () => import('@/views/InsureItems'),
        meta: {
            title: '香港保险'
        }
    },
    
    
]

// 当 children 不为空的主导航只有一项时，则隐藏
// let asyncRoutes = [  
// ]

// const lastRoute = [{
//     path: '*',
//     component: () => import('@/views/404'),
//     meta: {
//         title: '404',
//         sidebar: false
//     }
// }]

const router = new VueRouter({
    routes: constantRoutes,
    scrollBehavior() {
        return {
            y:0,
        }
    }
})

// 解决路由在 push/replace 了相同地址报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

router.afterEach(() => {
    // store.state.settings.enableProgress && NProgress.done()
})

export default router
