import Vue from 'vue'
// 文件已经挂载全局 使用方法 例：{{number | format}} 如有增添写好注释和示例
// 区分币种
/*
 * 整数的格式化
 * 100 -> 100
 * 1000 -> 1,000
 * 1000.11 -> 1,000
 * 1000000 -> 1,000,000
 * */
Vue.filter('format', number => {
  if (number === null || number === undefined || number === '') return ''
  if (number === '-') return number
  return parseInt(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})
/*
 * 浮点数的格式化，保留小数点后两位，没有自动补齐两位0
 * 100 -> 100.00
 * 1000 -> 1,000.00
 * 1000.11 -> 1,000.11
 * 1000000 -> 1,000,000.00
 * */
Vue.filter('formatFloat', number => {
  if (number === '******') return '******'
  if (number === null || number === undefined || number === '') return ''
  if (Vue.prototype.verification(number)) {
    let Calculate = (Math.round(Number(number) *100) / 100).toString()
    let JudgeVal = Calculate.indexOf('.')
    if (JudgeVal < 0) {
      JudgeVal = Calculate.length
      Calculate += '.'
    }
    while (Calculate.length <= JudgeVal + Number(2)) {
      Calculate += '0'
    }
    return Calculate.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
})
