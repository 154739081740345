// 保险
import api from '../../api/request'

const state = {}
const getters = {}

const actions = {
    // 我的保险
    getInsurances({ commit }) {
        return new Promise((resolve, reject) => {
            api.get('/insurances').then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getInsurancesMoney({ commit },params) {
        return new Promise((resolve, reject) => {
            api.get('/insurance/plans/insurance',params).then(res => {
                commit('nothing',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

const mutations = {
    
    nothing() {
        
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
